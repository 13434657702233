$primary: #2962ff;

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', monospace;
}

button, input, textarea {
  font-family: 'Fira Sans', monospace;
}

a {
  color: $primary;
}

#top-bar {
  background: $primary;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  #logo {
    background: transparent;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin-right: 24px;
  }

  nav {
    flex: 1;
    ol {
      display: flex;
      flex-direction: row;
    }
  }

  a, button {
    display: inline-block;
    border: 0;
    border-radius: 6px;
    padding: 8px 16px;
    background: darken($primary, 10%);
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }

  #user {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 36px;
      height: 36px;
      border-radius: 6px;;
      margin-right: 8px;
    }
  }
}

.btn {
  background: $primary;
  color: white;
  border: 0;
  border-radius: 6px;
  padding: 8px 12px;
  font-weight: 500;
  cursor: pointer;
}

.card {
  box-shadow: 1px 1px 4px rgba(0,0,0,0.1);
  padding: 32px;
  border-radius: 12px;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  h1, h2, h3 {
    font-weight: 300;

    a {
      display: block;
      text-decoration: none;
    }
  }
}

#invite {
  .card {
    max-width: 400px;
    margin: 32px auto;
  }

  textarea {
    display: block;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 16px;
  }
}

#sign-in {
  .card {
    max-width: 400px;
    margin: 32px auto;
    text-align: center;

    .btn {
      display: block;
      width: 100%;
      margin-top: 8px;
      font-size: 20px;
    }
  }
}

.content {
  max-width: 960px;
  margin: 0 auto;
}

.app-card {
  padding: 16px;
  width: 460px;

  h3 {
    margin-bottom: 6px;
  }

  .app-id {
    font-size: 14px;
    color: #888;
    b {
      color: #666;
    }
  }
}

#app-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .app-card {
    margin: 8px;
  }
}

a.delete {
  color: red;
  cursor: pointer;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  &.full-page {
    transform: scale(1.5);
  }
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $primary;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.page-center {
  margin-top: 128px;
  text-align: center;
}
